import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

//CSS
import '../css/main.css';

const contact = () => (
  <Layout>
    <SEO title="Contact form" />
    <form className="contact-form bg-light" name="contact" netlify>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSctkjxFg3EsdDKbOQpVGIXRN4HsXASQsilvqNcUJtVdU79s3g/viewform?embedded=true"
        width="640"
        height="1088"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        title="contact-form"
      >
        Loading…
      </iframe>
    </form>
  </Layout>
);

export default contact;
